import { frontendURL } from '../../../helper/URLHelper';

const DialpadView = () => import('/app/javascript/dashboard/components/layout/sidebarComponents/DialPad.vue');
export const routes = [
    {
        path: frontendURL('accounts/:accountId/dialpad'),
        name: 'dialpad',
        roles: ['administrator', 'agent'],
        component: DialpadView,
        meta: {
            permissions: ['agent'],
          },
    },
];
