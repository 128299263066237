import { frontendURL } from '../../../../helper/URLHelper';

const dialpad = accountId => ({
    parentNav: 'dialpad',
    routes: ['dialpad'],
    menuItems: [
        {
            icon: 'dialpad-icon',
            label: 'DIALPAD',
            key: 'dialpad',
            hasSubMenu: false,
            toState: frontendURL(`accounts/${accountId}/dialpad`),
            toStateName: 'dialpad_contact',
        },
    ],
});

export default dialpad;
