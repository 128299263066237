<template>
  <div v-if="showModal" class="modal-overlay">
    <div class="modal-content">
      <h2 class="portal_heading">This Portal is Private</h2>
      <form @submit.prevent="handleSubmit">
        <label for="password">Enter Password:</label>
        <input type="password" id="password" v-model="password" />
        <div class="portal-login">
          <button class="cancel_button" @click="closeModal">Close</button>
          <button type="submit" class="button">Login</button>
        </div>
      </form>
      <div v-if="error" class="error">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      password: '',
      error: null,
    };
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    portal: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      baseUrl: 'getbaseUrl',
    }),
  },
  methods: {
    async handleSubmit() {
      console.log(this.baseUrl);
      try {
        const response = await axios.post(`${this.baseUrl}/access_private_portal`, {
          params: {
            password: this.password,
            account_id: this.accountId,
            portal_id: this.portal.id,
          },
        });
        console.log(response.data);
        if (response.data.data === 'Login successful') {
          this.$emit('login-success');
        } else if (response.data.data === 'Invalid credentials') {
          alert('Please enter valid credentials');
        } else if (response.data.data === 'Portal is not private') {
          alert('This portal is not private');
        }
      } catch (error) {
        console.error('Error fetching API:', error);
        this.error = 'Error fetching API';
      }
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.modal-content {
  padding: 20px 50px 20px 50px;
  border-radius: 10px;
  border: 2px solid white;
  background-color:white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.portal-login{
  display:flex;
  justify-content: space-between;
}
.cancel_button{
  background-color: red;
  color:white;
}
.portal_heading{
  text-align: center;
  font-size: 20px;
  padding-bottom: 20px;
}
.error {
  color: red;
  margin-top: 10px;
}
</style>
