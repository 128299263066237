/* global axios */
import ApiClient from './ApiClient';

class InboxMembers extends ApiClient {
  constructor() {
    super('inbox_members', { accountScoped: true });
  }

  update({ inboxId, agentList, domainList }) {
    console.log(domainList);
    return axios.patch(this.url, {
      inbox_id: inboxId,
      user_ids: agentList,
      domain: domainList 
    });
  }
}

export default new InboxMembers();
