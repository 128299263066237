<template>
  <div class="main-container">
    <div class="toggle-height" @click="toggleHeight">{{ $t(buttonText) }}</div>
    <div v-if="showContent" class="fixing-content">
      <div v-if="contacts.length > 0" class="hold-calls">
        {{ $t('CONVERSATION.REPLYBOX.CALLS_ON_HOLD') }}
      </div>
      <div class="hold-call-data">
        <table>
          <tbody>
            <template v-if="contacts.length > 0">
              <tr
                v-for="contact in contacts"
                :key="contact.id"
                class="contact-person"
              >
                <td>{{ contact.From }}</td>
                <td class="contact-icon">
                  <fluent-icon
                    icon="incoming-call"
                    label="call"
                    title="call"
                    size="30"
                    class="call-button"
                  />
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="2">
                  <strong>
                    {{ $t('CONVERSATION.REPLYBOX.NO_CALLS_ON_HOLD') }}
                  </strong>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      showContent: false,
      buttonText: 'CONVERSATION.REPLYBOX.CALLS_ON_HOLD',
      contacts: [],
    };
  },
  mounted() {
    this.holdcontactData();
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      baseUrl: 'getbaseUrl',
      holdCall: 'contacts/getHoldCalldata'
    }),
  },
  watch: {
    holdCall(newValue) {
      this.holdcontactData();
    }
  },
  methods: {
    toggleHeight() {
      this.showContent = !this.showContent;
      if (this.showContent == true) {
        this.buttonText = 'CONVERSATION.REPLYBOX.ONGOING_CALL';
      } else {
        this.buttonText = 'CONVERSATION.REPLYBOX.CALLS_ON_HOLD';
      }
    },
    async holdcontactData() {
      try {
        this.contacts = this.holdCall;
        this.sendContactsToParent();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    sendContactsToParent() {
      this.$emit('contacts-updated', this.contacts);
      if (this.contacts.length > 0) {
        this.$store.dispatch('contacts/toggleHoldStatusTrue');
      } else {
        this.$store.dispatch('contacts/toggleHoldStatusFalse');
      }
    },
  },
};
</script>

<style scoped>
.fixing-content {
  position: absolute;
  overflow: auto;
  left: 30px;
  height: 72%;
  width: 85%;
  z-index: 8;
  bottom: 78px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: initial;
  border-radius: 5px;
  margin-bottom: 3%;
}
body.dark .fixing-content {
  background: #151718;
  color: #fff;
}

body:not(.dark) .fixing-content {
  background: #fcfafa;
}

body.dark .toggle-height {
  color: #fff;
}
.toggle-height {
  cursor: pointer;
  font-weight: 600;
  text-align: center;
  z-index: 9;
  bottom: 15px;
  font-size: 20px;
}
.contact-person {
  text-align: initial;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  cursor: initial;
}
.contact-person:hover {
  transform: scale(1.001);
}
body:not(.dark) .contact-person {
  border-bottom: 2px solid lightblue;
}
.hold-calls {
  position: sticky;
  top: 0;
  font-size: 20px;
  padding-bottom: 10px;
  font-weight: 600;
  cursor: initial;
  border-radius: 5px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
}
body.dark .hold-calls {
  background: #151718;
  color: #fff;
}

body:not(.dark) .hold-calls {
  color: #151718;
  background-color: #fff;
}
.hold-call-data {
  /* height: 265px; */
  /* overflow: auto; */
  display: flex;
}

.call-button {
  color: red;
  display: flex;
  justify-content: center;
}

::-webkit-scrollbar {
  width: 8px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: grey;
  border-radius: 5px;
}
.contact-icon {
  display: flex;
  justify-content: center;
}
</style>
