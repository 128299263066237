import { frontendURL } from '../../../../helper/URLHelper';
const SettingsContent = () => import('../Wrapper.vue');
const Index = () => import('./Index.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/twilio-credentials'),
      roles: ['administrator'],
      component: SettingsContent,
      meta: {
        permissions: ['administrator', 'agent'],
      },
      props: {
        headerTitle: 'GENERAL_SETTINGS.TWILIO_CRED',
        icon: 'call-start',
        showNewButton: false,
      },
      children: [
        {
          path: '',
          name: 'twilio_credentials',
          component: Index,
          meta: {
            permissions: ['administrator', 'agent'],
          },
          roles: ['administrator'],
        },
      ],
    },
  ],
};
