<template>
  <div class="main-draggable-modal">
    <div v-if="show" class="draggable-modal resizable"
      :style="{ top: modalPosition.y + 'px', left: modalPosition.x + 'px' }">
      <div class="modal-header" @mousedown.stop="startDragging">
        <div class="modal-contact-detail">
          <span v-if="!holdui" class="modal-title">{{
            $t('CONVERSATION.REPLYBOX.CONNECTING')
            }}</span>

          <!-- <span v-if="!holdui" class="modal-title">{{ contacts[0].From }}</span> -->
          <span v-if="contacts && contacts.length > 0 && !holdui" class="modal-title">{{ contacts[0].From }}</span>

          <div v-if="holdui" class="incomingcall-data">
            <span v-if="currentContact" class="modal-title">{{
              currentContact.name
              }}</span>
            <span v-if="currentContact" class="modal-title">{{
              currentContact.phone_number
              }}</span>
          </div>
        </div>
      </div>
      <div class="modal-content">
        <draggable v-if="modalVisible">
          <div class="modal" ref="modalRef">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header" @mousedown.prevent>
                  <h5 v-if="!incomingCall" class="modal-time">
                    <span v-if="formatted === '00:00:00'" class="ringing-indicator">Ringing...</span>
                    <span v-else>{{ formatted }}</span>
                  </h5>
                </div>
                <div class="modal-body">
                  <div class="image-body">
                    <img :src="imgUrl" />
                  </div>
                </div>
                <div class="calling-button">
                  <div v-if="incomingCall" class="outgoing-button" @click="oncall">
                    <woot-button icon="call-cut" title="call-start" />
                  </div>
                  <div v-if="!incomingCall" class="calltaking">
                    <div>
                      <woot-button icon="chat" title="open-conversation"
                        @click="currentContact && openConversation(currentContact)" />
                    </div>
                    <div>
                      <woot-button :icon="currentIcon" @click="toggleButton" />
                    </div>
                  </div>
                  <div v-if="!incomingCall" class="call-button">
                    <woot-button icon="call-cut" title="hangup" @click="onCancel" />
                  </div>

                  <div v-if="incomingCall" class="call-button">
                    <woot-button icon="call-cut" title="hangup" @click="onreject" />
                  </div>
                </div>
                <div v-if="!incomingCall" class="hold-contact">
                  <div v-if="contacts.length > 0" class="hold-calls">
                    {{ $t('CONVERSATION.REPLYBOX.CALLS_ON_HOLD') }}
                  </div>
                  <div v-for="(contact, index) in filteredContacts" :key="index" class="contact-person">
                    <!-- Display contact information -->
                    <p class="contact-number">{{ contact.From }}</p>
                    <p class="hold-icon">
                      <fluent-icon icon="incoming-call" label="call" title="call" size="30" class="call-button" />
                    </p>
                  </div>
                </div>
                <div class="open-conversation">
                  <!-- Calls on Hold -->
                  <waiting-queue @contacts-updated="handleContactsUpdated" />
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import twillioMixin from 'dashboard/mixins/twilioCall';
import { mapGetters } from 'vuex';
import DialerPad from './DialerPad';
import WaitingQueue from './WaitingQueue.vue';

export default {
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.modal) {
        this.$refs.modal.addEventListener('resize', this.handleResize);
      }
    });
  },
  components: {
    draggable,
    DialerPad,
    WaitingQueue,
  },
  mixins: [twillioMixin],
  data() {
    return {
      modalVisible: true,
      imgUrl:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpCKq1XnPYYDaUIlwlsvmLPZ-9-rdK28RToA&usqp=CAU',
      currentIcon: 'mic',
      showCallButton: true,
      dragEnabled: true,
      isDragging: false,
      modalPosition: { x: 70, y: 295 },
      userInput: '',
      contacts: [],
      modalPosition: {
        x: 70,
        y: 295,
        width: 400, // initial width
        height: 300, // initial height
      },
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    formatted: {
      type: String,
      required: true,
      default: false,
    },
    hangup: Function,
    selectedContact: {
      type: Object,
      default: null,
    },
  },

  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      accountId: 'getCurrentAccountId',
      baseUrl: 'getbaseUrl',
      incomingCall: 'contacts/getIncomingCall',
      ishold: 'contacts/getHoldState',
      holdui: 'contacts/getHoldui'
    }),

    currentContact() {
      if (this.selectedContact) {
        return this.selectedContact;
      } else if (
        this.currentChat &&
        this.currentChat.meta &&
        this.currentChat.meta.sender
      ) {
        return this.$store.getters['contacts/getContact'](
          this.currentChat.meta.sender.id
        );
      } else {
        return null; // or any default value you prefer
      }
    },
    filteredContacts() {
      if (this.contacts.length > 0) {
        return this.contacts.slice(0, 2); // Only return first two contacts
      }
      return this.contacts;
    },
  },
  methods: {
    async onCancel() {
      if (this.ishold == true) {
        this.$store.dispatch('contacts/toggleholdCallAvailableStatusFalse');
        console.log('Hold Ui Display');
        try {
          const response = await axios.get(
            `${this.baseUrl}/twilio/waiting_calls`,
            {
              params: {
                account_id: this.accountId,
              },
            }
          );
          console.log('Response', response);
          this.contacts = response.data.data;
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }

      this.hangup();
    },
    openConversation(currentContact) {
      console.log(" this is the current contact:::::::::::::");
      console.log(currentContact);
      const contactId = currentContact;
      axios.post(`${this.baseUrl}/twilio/contact_conversations`, {
        params: {
          contact_id: contactId,
        },
      })
        .then(response => {
          const conversations_id = response.data[0].display_id;
          console.log(conversations_id);
          this.$router.push(`/app/accounts/${this.accountId}/conversations/${conversations_id}`);
        })
        .catch(error => {
          console.error('There is no conversation of this contact', error);
        });
    },
    handleResize(event) {
      const modal = event.target;
      const width = modal.offsetWidth;
      const height = modal.offsetHeight;
      // Update the modal's size
      this.modalPosition.width = width;
      this.modalPosition.height = height;
    },
    minimizeModal() {
      this.modalPosition.width = 200;
      this.modalPosition.height = 100; 
    },
    maximizeModal() {
      this.modalPosition.width = 800; 
      this.modalPosition.height = 600; 
    },
    toggleButton() {
      this.currentIcon = this.showCallButton ? 'speaker-mute' : 'mic';
      this.showCallButton = !this.showCallButton;
    },
    startDragging(event) {
      this.isDragging = true;
      document.body.style.userSelect = 'none';
      const modalRect = this.$el.getBoundingClientRect();
      this.modalPosition.x = event.clientX - modalRect.left;
      this.modalPosition.y = event.clientY - modalRect.top;
      window.addEventListener('mousemove', this.handleDragging);
      window.addEventListener('mouseup', this.stopDragging);
    },
    handleDragging(event) {
      if (this.isDragging) {
        this.modalPosition.x = event.clientX;
        this.modalPosition.y = event.clientY;
      }
    },
    stopDragging() {
      this.isDragging = false;
      window.removeEventListener('mousemove', this.handleDragging);
      window.removeEventListener('mouseup', this.stopDragging);
    },
    handleInput(value) {
      if (!isNaN(value)) {
        this.userInput += value; // Append the value to userInput
      }
    },
    handleDelete() {
      this.userInput = this.userInput.slice(0, -1);
    },
    oncall() {
      this.$emit('incomingCallAcceptButton');
      // this.incomingCallAcceptButton();
    },
    onreject() {
      this.$emit('rejectCall');
    },
    handleContactsUpdated(contacts) {
      // Handle the updated contacts data received from the child component
      console.log('Received contacts from child component:', contacts);
      this.contacts = contacts;
    },
  },
};
</script>

<style scoped>
/* Add your styling here */
.image-body {
  width: 50%;
  height: 50%;
}

.image-body img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.modal-body {
  display: flex;
  justify-content: center;
}

body:not(.dark) .calltaking button {
  /* Your desired background color */
  /* Add any other common styles for all buttons here */
  background-color: darkblue;
  margin: 0px 10px;
}

.call-button button:last-child {
  /* Additional styles for the last button, if needed */
  background-color: red;
}

.timer {
  text-align: center;
}

.modal-mask {
  background: transparent !important;
}

body.dark .draggable-modal {
  background-color: #000;
}

/* Apply background color for default mode */
body:not(.dark) .draggable-modal {
  background-color: #1f93ff;
}

/* Common styles for draggable-modal */
.draggable-modal {
  border-radius: 5%;
}

.modal-header .modal-time {
  color: #fff;
}

.modal-title {
  text-align: center;
  margin: 5px 0px;
}

.modal-mask>>>.modal-container {
  background: transparent;
}

.modal-mask>>>.modal-container>.button {
  display: none;
}

.draggable-modal {
  position: fixed;
  max-width: 400px;
  width: 100%;
  padding: 10px;
  cursor: grab;
  z-index: 99;
}

.open-conversation {
  text-align: center;
  padding: 6px;
  border-radius: 5%;
  border: 2px solid lightblue;
  cursor: pointer;
  color: #fff;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: #fff;
}

.main-draggable-modal {
  z-index: 599;
}

.modal-content {
  padding: 10px;
}

.modal-contact-detail {
  display: flex;
  flex-direction: column;
}

.dialer-pad {
  position: absolute;
  top: 190px;
  background-color: #000;
}

.dialer-input input {
  background-color: #fff;
  color: #000;
  margin: 0px;
  font-size: 25px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.calltaking {
  display: contents;
}

.calling-button {
  display: flex;
  justify-content: space-evenly;
  margin: 10px;
}

.outgoing-button button:first-child {
  background-color: #44ce4b;
}

.call-button {
  color: red;
}

.contact-person {
  display: flex;
  transition: transform 0.3s ease;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: initial;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  padding: 8px 0px;
}

.contact-number {
  font-size: 16px;
  width: 76%;
  padding-left: 6%;
}

.contact-person:hover {
  transform: scale(1.001);
}

body.dark .contact-person {
  color: #fff;
  border-color: hsl(198 6.6% 15.8% / 0.3);
}

/* Apply background color for default mode */
body:not(.dark) .contact-person {
  background-color: #fff;
  border-bottom: 2px solid lightblue;
}

.hold-contact {
  border-radius: 2%;
  margin-bottom: 3%;
}

body.dark .hold-contact {
  border-color: hsl(198 6.6% 15.8% / 0.3);
  border-bottom: 1px solid transparent;
  background: #151718;
}

.hold-icon {
  display: flex;
  justify-content: center;
  margin: 0px;
  align-items: end;
  width: 20%;
}

.hold-calls {
  position: sticky;
  top: 0;
  font-size: 20px;
  padding-bottom: 10px;
  font-weight: 600;
  cursor: initial;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 1;
}

body.dark .hold-calls {
  background: #151718;
  color: #fff;
}

body:not(.dark) .hold-calls {
  color: #151718;
  background-color: #fff;
}

.incomingcall-data {
  display: flex;
  flex-direction: column;
}

.resizable {
  resize: both;
  overflow: auto;
}

.resizable:hover {
  cursor: nw-resize;
}
</style>
