<template>
  <div class="h-full w-16 bg-white dark:bg-slate-900 border-r border-slate-50 dark:border-slate-800/50 rtl:border-l rtl:border-r-0 flex justify-between flex-col">
    <div class="flex flex-col items-center">
      <logo
        :source="logoSource"
        :name="installationName"
        :account-id="accountId"
        class="m-0 w-16 mb-10"
      />
      <primary-nav-item
        v-for="menuItem in menuItems"
        :key="menuItem.toState"
        :icon="menuItem.icon"
        :name="menuItem.label"
        :to="menuItem.toState"
        :is-child-menu-active="menuItem.key === activeMenuItem"
      />
      <woot-button icon="call-start" @click="emitCancelButtonEvent" />
      <!-- <div v-if="showDraggablemodal">
        <div id="selected-options" class="selected-options">
          <div class="select-contacts" @click="activeModal(1)">
            {{ $t('CONVERSATION.REPLYBOX.CONTACTS') }}
          </div>
          <div class="select-dialer" @click="activeModal(2)">
            {{ $t('CONVERSATION.REPLYBOX.DIALPAD') }}
          </div>
        </div>
        <div>
          <dial-pad
            :show="showDialpadmodal"
            :result="result"
            @cancel="emitCancelButtonEvent"
            @formattedTimeChanged="handleFormattedTimeChange"
            @timer-stopped="handleTimerStopped"
          />
        </div>
        <div class="my-dialpad">
          <dialer-pad
            :show="showDialermodal"
          />
        </div>
      </div> -->

      <div  class="dialpad-dialer" :class="{ 'none': !isHidden }">
        <div id="selected-options" class="selected-options">
          <div class="select-contacts"
          :class="{ 'active': !showDraggablemodal }"
          @click="showDraggablemodal=false">
            {{ $t('CONVERSATION.REPLYBOX.CONTACTS') }}
          </div>
          <div class="select-dialer"
          :class="{ 'active': showDraggablemodal }"
          @click="showDraggablemodal=true">
            {{ $t('CONVERSATION.REPLYBOX.DIALPAD') }}
          </div>
        </div>
        <div>
          <dial-pad
            :show="!showDraggablemodal"
            :result="result"
            @cancel="emitCancelButtonEvent"
            @formattedTimeChanged="handleFormattedTimeChange"
            @timer-stopped="handleTimerStopped"
          />
        </div>
        <div class="my-dialpad">
          <dialer-pad
            :show="showDraggablemodal"
            @formattedTimeChanged="handleFormattedTimeChange"
            @timer-stopped="handleTimerStopped"
          />
        </div>
      </div>
      <span class="call-timer">{{ dynamicFormattedTime }}</span>
      <span class="call-timer">{{ calltiming }}</span>
      <div>
        <draggable-file
          class="dialpadModal"
          :show="showDragmodal"
          :formatted="formattedTime"
          :hangup="hangupIncomingCall"
          :selectedContact="selectedContact"
          @incomingCallAcceptButton="acceptIncomingCall"
          @rejectCall="rejectIncomingCall"
        />
      </div>
    </div>
    <div class="flex flex-col items-center justify-end pb-6">
      <primary-nav-item
        v-if="!isACustomBrandedInstance"
        icon="book-open-globe"
        name="DOCS"
        :open-in-new-page="true"
        :to="helpDocsURL"
      />
      <notification-bell @open-notification-panel="openNotificationPanel" />
      <agent-details @toggle-menu="toggleOptions" />
      <options-menu
        :show="showOptionsMenu"
        @toggle-accounts="toggleAccountModal"
        @show-support-chat-window="toggleSupportChatWindow"
        @key-shortcut-modal="$emit('key-shortcut-modal')"
        @close="toggleOptions"
      />
    </div>
  </div>
</template>
<script>
import Logo from './Logo';
import PrimaryNavItem from './PrimaryNavItem';
import OptionsMenu from './OptionsMenu';
import AgentDetails from './AgentDetails';
import NotificationBell from './NotificationBell';
import wootConstants from 'dashboard/constants/globals';
import { frontendURL } from 'dashboard/helper/URLHelper';
import { ACCOUNT_EVENTS } from '../../../helper/AnalyticsHelper/events';
import DialPad from './DialPad.vue';
import DialerPad from './ContactDailerpad';
import { mapGetters } from 'vuex';
import DraggableFile from '../../widgets/WootWriter/Draggable.vue';
let token;
let device;
let call;

export default {
  components: {
    Logo,
    PrimaryNavItem,
    OptionsMenu,
    AgentDetails,
    NotificationBell,
    DialPad,
    DialerPad,
    DraggableFile,
  },
  props: {
    isACustomBrandedInstance: {
      type: Boolean,
      default: false,
    },
    logoSource: {
      type: String,
      default: '',
    },
    installationName: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    activeMenuItem: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      helpDocsURL: wootConstants.DOCS_URL,
      showOptionsMenu: false,
      showDraggablemodal: false,
      result: [],
      showCallButton: true,
      currentIcon: 'call-start',
      calltitle: 'CONVERSATION.REPLYBOX.CALL',
      showDragmodal: false,
      timerRunning: false,
      seconds: 0,
      minutes: 0,
      hours: 0,
      twilio_call: {},
      selectedContact: {},
      dynamicFormattedTime: '',
      calltiming: '',
     interval: null,
     previousFormattedTime: '',
     showSelectedOptions: false ,
     showDialermodal: false,
     showDialpadmodal: false,
     activeComponent: '',
     activeDialpad: 1,
     activeDialerPad: 2,
     currentActive: 0,
     isHidden: false
    };
  },
  mounted() {
    this.startupClient();
      // this.interval = setInterval(this.checkFormattedTime, 1000);
  },
  computed: {
    ...mapGetters({
      baseUrl: 'getbaseUrl',
      ishold: 'contacts/getHoldState',
      holdCallUi: 'contacts/getHoldCallUidata'
    }),
    formattedTime() {
      return (
        this.pad(this.hours) +
        ':' +
        this.pad(this.minutes) +
        ':' +
        this.pad(this.seconds)
      );
    },
  },
  watch: {
    holdCallUi(newValue) {
      console.log("Data",newValue);
      if(newValue.count===0)
      {
        this.showDragmodal=false;
      }
    }
  },
  methods: {
    frontendURL,
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    handleFormattedTimeChange(time) {
    this.dynamicFormattedTime = time;
  },
  handleTimerStopped() {
      this.dynamicFormattedTime = '';
    },
    openNotificationPanel() {
      this.$track(ACCOUNT_EVENTS.OPENED_NOTIFICATIONS);
      this.$emit('open-notification-panel');
    },
    async emitCancelButtonEvent() {
      console.log("showing modal");
      try {
        this.isHidden=!this.isHidden;
        this.showDraggablemodal=!this.showDraggablemodal;
        this.activeModal(this.currentActive);
        this.result = await this.$store.dispatch('contacts/dialpadContacts');
      } catch (error) {
        console.error(error);
      }
    },
    checkFormattedTime() {
    if (window.formattedTime === this.previousFormattedTime) {
      this.calltiming = '';
    } else {
      this.calltiming = window.formattedTime;
      this.previousFormattedTime = window.formattedTime;
    }
    console.log('Updated formattedTime:', window.formattedTime, this.calltiming);
  },

  showDialPadComponent() {
      this.activeComponent = 'dial-pad';
    },

    showDialerPadComponent() {
      this.activeComponent = 'dialer-pad';
      this.showDialeremodal = true;
    },
    async handleIncomingCall(call) {
      this.getAudioDevices();
      this.twilio_call = call;
      const response = await axios.get(
        `${this.baseUrl}/twilio/fetch_contact`,
        {
          params: {
            account_id: this.accountId,
            phone_number: call.parameters.From,
          },
        }
      );
      this.resetTimer();
      this.$store.dispatch('contacts/toggleAuthStatusTrue');
      this.$store.dispatch('contacts/toggleholdCallAvailableStatusTrue');
      this.showDragmodal = true;
      console.log("this is the phone::::::::::",response.data.data);
      this.selectedContact = {
        phone_number: response.data.data.phone_number,
        name: response.data.data.name,
      };
      let data = call.parameters;
      data.account_id = this.accountId;
      await axios.post(`${this.baseUrl}/twilio/handle_incoming_call`, data);

      call.on('cancel', this.handleDisconnectedIncomingCall);
      call.on('disconnect', this.handleDisconnectedIncomingCall);
      call.on('reject', this.handleDisconnectedIncomingCall);
    },

    acceptIncomingCall() {
      this.twilio_call.accept();
      this.startTimer();
      this.$store.dispatch('contacts/toggleAuthStatusFalse');
    },

    rejectIncomingCall() {
      this.twilio_call.reject();
      this.$store.dispatch('contacts/toggleAuthStatusFalse');
    },

    hangupIncomingCall() {
      console.log('Disconnect incoming Call');
      this.twilio_call.disconnect();
    },

    handleDisconnectedIncomingCall() {
      if (this.ishold == true) {
        this.resetTimer();
        this.startTimer();
      } else if (this.ishold == false) {
        this.showDragmodal = false;
        this.stopTimer();
      }
    },

    addDeviceListeners(device) {
      device.on('registered', function () {
        console.log('Twilio.Device Ready to make and receive calls!');
      });
      device.on('error', function (error) {
        console.log('Twilio.Device Error: ' + error.message);
      });
      device.on('incoming', this.handleIncomingCall);
    },

    async intitializeDevice() {
      console.log('Initializing device');
      device = new Twilio.Device(token, {
        logLevel: 1,
        closeProtection: true,
      });
      this.addDeviceListeners(device);
      device.register();
    },

    async getAudioDevices() {
      console.log('Inside getAudioDevices::::::::::::::::::::');
      await navigator.mediaDevices.getUserMedia({ audio: true });
    },

    async startupClient() {
      try {
        console.log('Requesting Access Token...');
        const data = await axios.get(`${this.baseUrl}/twilio/token`, {
          params: {
            account_id: this.accountId,
          },
        });
        token = data.data.token;
        console.log('got token' + token);
        await this.intitializeDevice();
      } catch (err) {
        console.log(err);
        console.log(
          'An error occurred. See your browser console for more information.'
        );
      }
    },
    startTimer() {
      this.timerRunning = true;
      this.timer = setInterval(this.updateTimer, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timerRunning = false;
    },
    updateTimer() {
      this.seconds++;
      if (this.seconds === 60) {
        this.seconds = 0;
        this.minutes++;
        if (this.minutes === 60) {
          this.minutes = 0;
          this.hours++;
        }
      }
    },
    pad(value) {
      return value < 10 ? '0' + value : value;
    },
    resetTimer() {
      this.stopTimer();
      this.seconds = 0;
      this.minutes = 0;
      this.hours = 0;
    },
    activeModal(val){
      console.log("this is Val:::",val);
      this.currentActive=val;
      if(this.currentActive==0)
      {
        this.currentActive=1;
      }
      if(this.currentActive==this.activeDialpad){
        this.showDialpadmodal=true;
        this.showDialermodal=false;
      }
      else if(this.currentActive==this.activeDialerPad){
        this.showDialpadmodal=false;
        this.showDialermodal=true;
      }
    },
    toggleClass() {
      this.isHidden = !this.isHidden;
    }  },
};
</script>

<style scoped>
.call-timer{
  color:black;
  font-size: 10px;
}
.selected-options{
  display: flex;
  width:35.6%;
  z-index:2;
  height:32px;
  position: absolute;
  left: 80px;
  top: 166px;
  justify-content: space-between;
  /* column-gap: 185px; */
  background-color: #fff;
  border-radius: 5px;
  padding: 0px 10px 0px 10px;
  transition: display 0s;
}
.select-contacts,
.select-dialer {
  width: 50%;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.my-dialpad{
  z-index:2;
  position: absolute;
  left: 57px;
  top: 183px;
  width:37.4%;
}
.selected {
  background-color: #1f93ff;
  color: #fff;
  padding: 5px 0px;
  border-radius: 10px;
}
.none{
  display: none;
}
.active {
  background-color: #1f93ff;
  border-radius:10px;
  color: #fff;
}
@media (max-width: 1440px) {
  .selected-options {
    width: 34.6%;
    top: 164px;
  }

}
</style>