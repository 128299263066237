  <template>
  <div  v-if="showDialerPad" class="dialpad" @keydown="handleKeydown">
    <div class="row" v-for="(row, index) in rows" :key="index">
      <button v-for="(key, idx) in row" :key="idx" @click="handleClick(key)">
        {{ key }}
      </button>
    </div>
    <div class="row">
      <button @click="handleDelete">{{ $t('CONVERSATION.CONTEXT_MENU.DELETE') }}</button>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      showDialerPad: false,
      rows: [
        ['1', '2', '3'],
        ['4', '5', '6'],
        ['7', '8', '9'],
        ['*', '0', '#'],
      ],
    };
  },
  methods: {
    handleClick(value) {
      this.$emit('input', value);
    },
    handleDelete() {
      this.$emit('delete');
    },
    handleKeydown(event) {
      const key = event.key;
      console.log('Key is :-', key,typeof(key));
      if (/^[0-9*#]$/.test(key)) {
        this.$emit('input', key);
      } else if (key === 'Backspace') {
        this.$emit('delete');
      }
    },
    hideDialerPad() {
      this.showDialerPad = true; // Hide the dialer pad
    },
  },
};
</script>
  
  <style scoped>
.dialpad {
  display: inline-block;
  padding: 10px;
  background-color: cyan;
  width: 343px;
  height: 200px;

}

.row {
  display: flex;
  justify-content: space-around;
  margin-bottom: 5px;
}

button {
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  background: #1f1c1c;
  border-radius: 50%;
}

body.dark .dialpad {
  background-color: #000;
}

/* Apply background color for default mode */
body:not(.dark) .dialpad {
  background-color: lightskyblue;
}
</style>
  