import { frontendURL } from '../../../../helper/URLHelper';
const SettingsContent = () => import('../Wrapper.vue');
const Index = () => import('./Index.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/sendgrid-auth'),
      roles: ['administrator'],
      component: SettingsContent,
      meta: {
        permissions: ['administrator', 'agent'],
      },
      props: {
        headerTitle: 'GENERAL_SETTINGS.SENDGRID_AUTH',
        icon: 'credit-card-person',
        showNewButton: false,
      },
      children: [
        {
          path: '',
          name: 'sendgrid-auth',
          meta: {
            permissions: ['administrator', 'agent'],
          },
          component: Index,
          roles: ['administrator'],
        },
      ],
    },
  ],
};
